import React, { useEffect, useState } from "react";
import {
  Dialog,
  styled,
  useTheme,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import axiosInstance from "../../api/axiosConfig";
import counterInstance from "../../api/counterConfig";
import { CounterData, PostDetail, UserDetail } from "../../types";
import { POST_DETAIL_API, USER_BY_IDS_API } from "../../api/endpoint";
import Comment from "../../components/comment";
import UserDetails from "../../components/user-details";
import PostDetailsCounters from "../../components/post-details-counter";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    backdropFilter: "blur(40px)",
  },
  "& .MuiDialog-paper": {
    borderRadius: theme.breakpoints.up("sm") ? "20px" : "18px",
    backgroundColor: theme.breakpoints.up("sm")
      ? "rgba(255, 255, 255, 1)"
      : "rgba(255, 255, 255, 0.7)",
    backdropFilter: "blur(10px)",
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(0),
  },
}));

interface PostDetailDialogProps {
  open: boolean;
  onClose: () => void;
  postDetail: PostDetail | null;
}

const PostDetailDialog: React.FC<PostDetailDialogProps> = ({
  open,
  onClose,
  postDetail,
}) => {
  const [counterData, setCounterData] = useState<CounterData | null>(null);
  const [userData, setUserData] = useState<UserDetail | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchCounters = async () => {
    if (postDetail) {
      try {
        const response = await counterInstance.get(
          `${POST_DETAIL_API}/${postDetail.user_id}/${postDetail.post_id}`,
        );
        const { Item } = response.data;
        setCounterData(Item);
      } catch (error) {
        console.log("Error fetching counter data:", error);
      }
    }
  };

  const fetchUserDetails = async () => {
    if (postDetail) {
      try {
        const response = await axiosInstance.get(
          `${USER_BY_IDS_API}${postDetail.user_id}`,
        );
        if (response?.data?.data?.[0]) {
          setUserData(response?.data?.data?.[0]);
        }
      } catch (error) {
        console.log("Error fetching counter data:", error);
      }
    }
  };

  useEffect(() => {
    fetchCounters();
    fetchUserDetails();
  }, [postDetail]);

  if (!postDetail || !counterData) return null;

  const imageUrl =
    postDetail?.imageUrl ||
    (postDetail?.files?.length
      ? `${process.env.REACT_APP_DEFAULT_AVATAR}/${postDetail.user_id}/WORKS/IMAGES/medium/${postDetail.files[0].name}`
      : "");
console.log("open", open, postDetail);
  return (
    <BootstrapDialog
      open={open}
      onClose={onClose}
      sx={{ m: 0, p: 0 }}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <DialogContent
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: isMobile ? "auto" : 400,
          maxHeight: "80vh",
          overflow: "hidden",
        }}
      >
        {/* Blurred background image */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${imageUrl || ""})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "blur(20px)",
            zIndex: 0,
          }}
        ></div>

        {/* Main image in the center */}
        <img
          src={imageUrl || ""}
          alt={postDetail.text}
          style={{
            height: isMobile ? "auto" : "400px",
            maxWidth: "100%",
            width: isMobile ? "100%" : "auto",
            borderRadius: isMobile ? 0 : "10px",
            objectFit: "contain",
            zIndex: 1,
            position: "relative",
          }}
        />
        <UserDetails
          userData={userData}
          isMobile={isMobile}
          postDetail={postDetail}
        />
      </DialogContent>

      {/* Counters Section */}
      <PostDetailsCounters
        postDetail={postDetail}
        counterData={counterData}
        userData={userData}
        isMobile={isMobile}
      />
      {/* Comments */}
      <Comment
        postId={postDetail.post_id}
        count={
          counterData?.comments?.N ? parseInt(counterData.comments.N, 10) : 0
        }
      />
    </BootstrapDialog>
  );
};

export default PostDetailDialog;