import React from 'react';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";

const Register = () => {
  return (
    <Box pt={10}>
      <Typography variant="h3">Register Page</Typography>
      <p>Register for the contests here.</p>
    </Box>
  );
};

export default Register;
