import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

// Create a theme with custom typography
const theme = createTheme({
  typography: {
    fontFamily: "Righteous, Somatic, Arial, sans-serif",
    h1: {
      fontFamily: "Righteous, Somatic, Arial, sans-serif",
    },
    h2: {
      fontFamily: "Righteous, Somatic, Arial, sans-serif",
    },
    h3: {
      fontFamily: "Righteous, Somatic, Arial, sans-serif",
    },
    h4: {
      fontFamily: "Righteous, Somatic, Arial, sans-serif",
      fontSize: "48px",
    },
    h5: {
      fontFamily: "Righteous, Somatic, Arial, sans-serif",
    },
    h6: {
      fontFamily: "Righteous, Somatic, Arial, sans-serif",
    },
    body1: {
      fontFamily: "Righteous, Somatic, Arial, sans-serif",
      fontSize: "14.7px",
    },
    body2: {
      fontFamily: "Righteous, Somatic, Arial, sans-serif",
    },
    button: {
      fontFamily: "Righteous, Somatic, Arial, sans-serif",
    },
  },
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

reportWebVitals(console.log);
