import React from "react";
import { Box, Grid, Typography, Button, InputBase } from "@mui/material";
import SectionBackground from "../../components/section-background";

interface TalkToUsProps {
  isMobile?: Boolean | null;
}

const TalkToUs: React.FC<TalkToUsProps> = ({ isMobile }) => {
  const inputStyles = {
    width: "100%",
    height: { xs: 50, md: 73 },
    border: "none",
    outline: "none",
    borderRadius: { xs: "12px", md: "16px" },
    boxShadow: "0px 8px 60px 0px rgba(0, 0, 0, 0.1)",
    padding: { xs: "8px 16px", md: "10px 20px" },
    fontSize: { xs: 16, md: 24 },
    background: "rgba(255, 255, 255, 0.8)",
    fontFamily: "Somatic",
    fontWeight: 400,
    lineHeight: { xs: "1.5", md: "90px" },
    letterSpacing: "0.03em",
  };

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <SectionBackground />
      {/* Content */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          pt: { xs: 5, md: 18 },
          pr: { xs: 5, md: 5 },
          pb: { xs: 5, md: 20 },
          pl: { xs: 5, md: 15 },
        }}
      >
        <Grid item xs={12} md={12} pb={3}>
          <Box sx={{ textAlign: "justify" }}>
            {isMobile ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant={"h6"}
                  sx={{
                    background:
                      "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    fontWeight: 700,
                    letterSpacing: 3,
                    mb: 4,
                  }}
                >
                  Talk To Us
                </Typography>
              </Box>
            ) : (
              <Typography color="#FFE834" variant="h3" fontSize={40}>
                Talk To Us
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid
              item
              xs={12}
              md={5}
              sx={{ pt: { xs: 1, md: 2 }, pb: { xs: 1, md: 2 } }}
            >
              <Typography
                color={isMobile ? "black" : "white"}
                fontSize={isMobile ? 14 : 30}
                sx={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
              >
                YOUR NAME
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{ pt: { xs: 1, md: 2 }, pb: { xs: 1, md: 2 } }}
            >
              <InputBase type="text" sx={inputStyles} />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{ pt: { xs: 1, md: 2 }, pb: { xs: 1, md: 2 } }}
            >
              <Typography
                color={isMobile ? "black" : "white"}
                fontSize={isMobile ? 14 : 30}
                sx={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
              >
                YOUR PHONE NUMBER
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{ pt: { xs: 1, md: 2 }, pb: { xs: 1, md: 2 } }}
            >
              <InputBase type="text" sx={inputStyles} />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{ pt: { xs: 1, md: 2 }, pb: { xs: 1, md: 2 } }}
            >
              <Typography
                color={isMobile ? "black" : "white"}
                fontSize={isMobile ? 14 : 30}
                sx={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
              >
                YOUR SCHOOL NAME
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{ pt: { xs: 1, md: 2 }, pb: { xs: 1, md: 2 } }}
            >
              <InputBase type="text" sx={inputStyles} />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{ pt: { xs: 1, md: 2 }, pb: { xs: 1, md: 2 } }}
            >
              <Typography
                color={isMobile ? "black" : "white"}
                fontSize={isMobile ? 14 : 30}
                sx={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
              >
                ROLE IN THE SCHOOL
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{ pt: { xs: 1, md: 2 }, pb: { xs: 1, md: 2 } }}
            >
              <InputBase type="text" sx={inputStyles} />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{ pt: { xs: 1, md: 2 }, pb: { xs: 1, md: 2 } }}
            >
              <Typography
                color={isMobile ? "black" : "white"}
                fontSize={isMobile ? 14 : 30}
                sx={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
              >
                MESSAGE
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{ pt: { xs: 1, md: 2 }, pb: { xs: 1, md: 2 } }}
            >
              <InputBase type="text" sx={inputStyles} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={2}
          pl={{ xs: 0, md: 5 }}
          sx={{ textAlign: "center" }}
        >
          {isMobile ? (
            <Button variant="contained" className="active-link" sx={{ mt: 5 }}>
              SUBMIT
            </Button>
          ) : (
            <Button
              // variant="contained"
              className="active-link submit_btn"
              sx={{
                borderRadius: "45px / 35px !important",
                padding: "5px 5px !important",
              }}
            >
              {"SUBMIT".split("").map((letter, index) => (
                <Typography key={index}>{letter}</Typography>
              ))}
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default TalkToUs;
