import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Avatar,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import axiosInstance from "../api/axiosConfig";
import { CommentProps } from "../types";
import { REACTIONS_API, USER_BY_IDS_API } from "../api/endpoint";
import heart_dynamic from "../assets/heart_dynamic.svg";

interface CommentWithUser extends CommentProps {
  userData?: any;
}

const Comment: React.FC<{ postId: string; count: number }> = ({
  postId,
  count,
}) => {
  const [commentsData, setCommentsData] = useState<CommentWithUser[]>([]);
  const [loading, setLoading] = useState(true);
  const commentRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [commentWidths, setCommentWidths] = useState<number[]>([]); // Array to hold the widths
  const isMobile = useMediaQuery("(max-width:600px)");

  const fetchComments = async () => {
    try {
      const response = await axiosInstance.get(
        `${REACTIONS_API}/${postId}/comment?page=&count=&webReq=true`,
      );
      if (response?.data?.comments?.length) {
        const comments = response.data.comments;
        setCommentsData(comments);
        const uniqueUserIds: any = [
          ...new Set(comments.map((comment: CommentProps) => comment.userId)),
        ];
        await fetchUserDetailsForComments(uniqueUserIds);
      }
    } catch (error) {
      console.error("Error fetching comments data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserDetailsForComments = async (userIds: string[]) => {
    try {
      const response = await axiosInstance.get(
        `${USER_BY_IDS_API}${userIds.join(",")}`,
      );
      const usersData = response?.data?.data || [];

      setCommentsData((prevComments) =>
        prevComments.map((comment) => {
          const userData = usersData.find(
            (user: any) => user.uid === comment.userId,
          );
          return { ...comment, userData };
        }),
      );
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (postId) {
      fetchComments();
    }
  }, [postId]);

  // Update the comment widths when comments change
  useEffect(() => {
    const updateWidths = () => {
      const newWidths = commentRefs.current.map((el) => el?.offsetWidth || 0);
      setCommentWidths(newWidths);
    };

    // Update widths initially and when comments change
    updateWidths();

    window.addEventListener("resize", updateWidths);
    return () => window.removeEventListener("resize", updateWidths);
  }, [commentsData]);

  if (loading) return <Typography p={5}>Loading comments...</Typography>;

  return (
    <Box pb={3}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography
          variant={isMobile ? "h6" : "h5"}
          sx={{
            background:
              "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            color: "transparent",
            fontWeight: 400,
            letterSpacing: isMobile ? 1 : 2,
            pl: isMobile ? 3 : 5,
            pt: isMobile ? 1 : 3,
            pb: isMobile ? 1 : 3,
          }}
          fontSize={{ xs: 16, md: 20 }}
        >
          Comments ({count})
        </Typography>
      </Box>

      {commentsData.length === 0
        ? null
        : commentsData.map((comment, index) => (
            <Box pl={isMobile ? 3 : 5} key={index}>
              <Typography
                variant="body2"
                color="text.secondary"
                pl={isMobile ? 1 : 2}
              >
                {moment(comment.created_at).fromNow()}
              </Typography>

              {/* Comment details */}
              <Box
                ref={(el: HTMLDivElement | null) => {
                  commentRefs.current[index] = el;
                }}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: isMobile ? "2px 4px" : "8px 16px",
                  backgroundColor: "#fff",
                  borderRadius: "25px",
                  clipPath:
                    "polygon(0% 3%, 15% 0%, 85% 0%, 100% 6%, 100% 91%, 95% 100%, 14% 100%, 0% 97%)",
                  width: "fit-content",
                  boxShadow: isMobile
                    ? "0px 3.2px 24px 0px rgba(0, 0, 0, 0.1)"
                    : "inherit",
                }}
              >
                {/* User Avatar */}
                <Avatar
                  alt={comment?.userData?.handle}
                  src={
                    comment?.userData?.defaultAvatar
                      ? `${process.env.REACT_APP_DEFAULT_AVATAR}/APP/UserAvatars/Avatar2.png`
                      : `${process.env.REACT_APP_DEFAULT_AVATAR}/${comment?.userData?.uid}/PROFILE/IMAGES/medium/${comment?.userData?.avatar}`
                  }
                  sx={{ width: 34, height: 34 }}
                />

                {/* Comment Content */}
                <Box sx={{ flexGrow: 1, mx: 2 }}>
                  <Typography
                    fontSize={{ xs: 12, md: 15 }}
                    fontWeight={400}
                    sx={{ p: 1, pb: 0 }}
                  >
                    {comment.text}
                  </Typography>
                </Box>
              </Box>

              {/* Comment Reactions (Moved Outside the Comment Box) */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  width: commentWidths[index]
                    ? `${commentWidths[index]}px`
                    : "auto",
                  pr: isMobile ? 0 : 3,
                }}
              >
                <IconButton size="small">
                  <img src={heart_dynamic} alt="dynamic" />
                </IconButton>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mr: 2 }}
                >
                  {comment.like}
                </Typography>
              </Box>
            </Box>
          ))}
    </Box>
  );
};

export default Comment;
