import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import great from "../assets/great.json";

interface GreatProps {
  width?: string | number;
  height?: string | number;
}

const Great: React.FC<GreatProps> = ({ width = "150px", height = "150px" }) => {
  return <Player autoplay loop src={great} style={{ width, height }} />;
};

export default Great;
