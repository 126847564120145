import React from "react";
import { Box, Avatar, Grid, Typography } from "@mui/material";
import { UserCounters, UserDetail, CoinsCounterProps } from "../types";
import UserBannerScores from "./user-banner-scores";

interface UserAvatarInfoProps {
  userDetail: UserDetail | null;
  userCounters: UserCounters | null;
  coinsData: CoinsCounterProps | null;
  isMobile: Boolean | null;
}

const UserAvatarInfo: React.FC<UserAvatarInfoProps> = ({
  userDetail,
  userCounters,
  coinsData,
  isMobile,
}) => {
  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent={isMobile ? "center" : "flex-start"}
    >
      <Grid item xs={12} md={2} display="flex" justifyContent="center">
        <Avatar
          alt={userDetail?.handle}
          src={
            userDetail?.defaultAvatar
              ? `${process.env.REACT_APP_DEFAULT_AVATAR}/APP/UserAvatars/Avatar2.png`
              : `${process.env.REACT_APP_DEFAULT_AVATAR}/${userDetail?.uid}/PROFILE/IMAGES/medium/${userDetail?.avatar}`
          }
          sx={{ width: 142, height: 142, m: isMobile ? "auto" : "inherit" }}
        />
      </Grid>
      
      <Grid item xs={12} md={6} p={1}>
        <Typography variant="h5" align={isMobile ? "center" : "inherit"}>
          {userDetail?.firstname || "Anonymous"}
        </Typography>
        <Typography variant="body1" align={isMobile ? "center" : "inherit"} pt={1}>
          {userDetail?.bio}
        </Typography>
      </Grid>
      
      <Grid item xs={12} md={4}>
        <UserBannerScores
          userCounters={userCounters}
          isMobile={isMobile}
          coinsData={coinsData}
        />
      </Grid>
    </Grid>
  );
};

export default UserAvatarInfo;
