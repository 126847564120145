import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../api/axiosConfig";
import counterInstance from "../../api/counterConfig";
import {
  Box,
  Typography,
  useMediaQuery,
  Grid2 as Grid,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { UserDetail, UserCounters, CoinsCounterProps } from "../../types";
import UserStats from "../../components/user-stats";
import UserBadges from "../../components/user-badges";
import UserAvatarInfo from "../../components/user-avatar-info";
import UserScores from "../../components/user-scores";
import Latest from "../../components/latest";
import user_profile_mobile from "../../assets/user_profile_mobile.svg";
import user_profile_desktop from "../../assets/user_profile_desktop.svg";
import {
  USER_COUNTER_API,
  COINS_API,
  USER_BY_HANDLENAME_API,
} from "../../api/endpoint";
import Loader from "../../components/loader";

const theme = createTheme({
  typography: {
    fontFamily: '"Somatic", Arial, sans-serif',
  },
});

const parseCounter = (counter: { N: string } | undefined): number =>
  parseInt(counter?.N || "0", 10);

const UserProfile: React.FC = () => {
  const { handle } = useParams<{ handle: string }>();
  const [userDetail, setUserDetail] = useState<UserDetail | null>(null);
  const [userCounters, setUserCounters] = useState<UserCounters | null>(null);
  const [coinsData, setCoinsData] = useState<CoinsCounterProps | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [userError, setUserError] = useState<string | null>(null);
  const [counterError, setCounterError] = useState<string | null>(null);

  const isMobile = useMediaQuery("(max-width:600px)");
  const searchTerm = `@${handle}`;
  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        setLoading(true);
        const userResponse = await axiosInstance.post(USER_BY_HANDLENAME_API, {
          searchTerm,
        });

        const userData = userResponse.data?.data?.[0];

        if (userData) {
          setUserDetail(userData);
          await fetchUserCounters(userData.uid);
          await fetchCoins(userData.uid);
        } else {
          setUserError("User data not found.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        setUserError("Error fetching user details.");
      }
    };

    const fetchUserCounters = async (uid: any) => {
      try {
        const countersResponse = await counterInstance.get(
          `${USER_COUNTER_API}/${uid}`,
        );
        const counters = countersResponse.data?.Item;

        if (counters) {
          const newCounters: Partial<UserCounters> = {};
          for (const key in counters) {
            if (counters[key].N) {
              newCounters[key as keyof UserCounters] = parseCounter(
                counters[key],
              );
            }
          }
          setUserCounters(newCounters as UserCounters);
        } else {
          setCounterError("User counters not found.");
        }
      } catch (error) {
        console.error("Error fetching user counters:", error);
        setCounterError("Error fetching user counters.");
      }
    };

    const fetchCoins = async (uid: string) => {
      try {
        const coinsResponse = await counterInstance.get(`${COINS_API}/${uid}`);
        const coinsItem = coinsResponse.data?.Item;

        if (coinsItem) {
          const newCoinsData: Partial<CoinsCounterProps> = {};
          for (const key in coinsItem) {
            if (coinsItem[key].N) {
              newCoinsData[key as keyof CoinsCounterProps] = parseCounter(
                coinsItem[key],
              );
            }
          }
          setCoinsData(newCoinsData as CoinsCounterProps);
        } else {
          console.log("Coins data not found.");
        }
      } catch (error) {
        console.error("Error fetching coin data:", error);
      }
    };

    const fetchData = async () => {
      try {
        await fetchUserDetail();
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [handle]);

  if (loading) return <Loader />;

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          flexGrow: 1,
          color: "#1F1D3AB2",
          background: `url(${
            isMobile ? user_profile_mobile : user_profile_desktop
          })`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Grid
          container
          spacing={1}
          justifyContent="space-between"
          sx={{
            pl: isMobile ? 2 : 8,
            pr: isMobile ? 2 : 8,
            pt: isMobile ? 2 : 8,
          }}
        >
          {userError ? (
            <Typography color="error">{userError}</Typography>
          ) : (
            <UserAvatarInfo
              userDetail={userDetail}
              userCounters={userCounters}
              coinsData={coinsData}
              isMobile={isMobile}
            />
          )}
        </Grid>
        <Box
          sx={{
            pl: isMobile ? 2 : 8,
            pr: isMobile ? 2 : 8,
            pt: isMobile ? 2 : 8,
          }}
        >
          <UserScores userCounters={userCounters} isMobile={isMobile} />
          <UserStats
            userCounters={userCounters}
            counterError={counterError}
            isMobile={isMobile}
            gradientTextStyles={gradientTextStyles}
          />
          <UserBadges
            userCounters={userCounters}
            counterError={counterError}
            isMobile={isMobile}
            gradientTextStyles={gradientTextStyles}
          />
        </Box>
        <Box
          sx={{
            pl: 1,
            pr: 1,
            pt: isMobile ? 2 : 8,
          }}
        >
          <Latest isUserProfile={true} userId={userDetail?.uid} />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

// Styles function for gradient text
const gradientTextStyles = (isMobile: boolean) => ({
  background:
    "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  fontWeight: 400,
  letterSpacing: isMobile ? 1 : 3,
  mb: isMobile ? 2 : 5,
});

export default UserProfile;
