import React from "react";
import { Grid2 as Grid, Box, Avatar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Player } from "@lottiefiles/react-lottie-player";
import crown1 from "../../assets/crown1.json"
import crown2 from "../../assets/crown2.json"
import crown3 from "../../assets/crown3.json"


// Define the types for a single champion
interface Champion {
  uid: string;
  defaultAvatarPath?: string;
  avatar: string;
  firstname?: string;
  coins: number;
  defaultAvatar: boolean;
  handle:string;
}

// Define the props type for the component
interface TopChampsProps {
  topChamps: Champion[];
  isMobile: boolean;
}

const transparentStyle = {
  background:
    "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  fontWeight: 700,
  letterSpacing: 1,
  fontFamily: "Somatic",
};

const renderChampion = (
  champ: Champion,
  index: number,
  isMobile: boolean,
  isFirst: boolean,
  crown: any,
  borderColor: string,
  boxShadow: string,
) => {
  const navigate = useNavigate();

  return (
    <Grid
      key={champ.uid + index}
      size={{ xs: 4, sm: 4 }}
      sx={{ textAlign: "center" }}
      mt={!isFirst ? 5 : 0}
    >
      <Box
        sx={{ padding: 2, position: "relative", cursor: "pointer" }}
        onClick={() => navigate(`/user/${champ.handle}`)}
      >
        <div
          style={{
            position: "absolute",
            top: isMobile ? "-3%" : "-15%",
            left:  isMobile ? "55%" : "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
          }}
        >
         
          <Player autoplay loop src={crown}  style={
              isMobile ? { width: 80, height: 80 } : { width: 150, height: 150 }
            }/>
        </div>
        <Avatar
          src={
            champ.defaultAvatar
              ? champ.defaultAvatarPath
              : `${process.env.REACT_APP_DEFAULT_AVATAR}/${champ?.uid}/PROFILE/IMAGES/medium/${champ?.avatar}`
          }
          alt={champ.firstname}
          sx={{
            width: isMobile ? 90 : 193,
            height: isMobile ? 90 : 193,
            margin: "auto",
            mt: 3,
            border: {
              xs: `4px solid ${borderColor}`,
              md: `10px solid ${borderColor}`,
            },
            boxShadow,
          }}
        />
        <Avatar
          sx={{
            width: isMobile ? 23 : 52,
            height: isMobile ? 23 : 52,
            textAlign: "center",
            color: "#FFFFFF",
            backdropFilter: "blur(3.23px)",
            background:
              "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
            borderRadius: "50%",
            p: 1,
            position: "absolute",
            left: isMobile ? "45%" : "40%",
            bottom: 0,
          }}
        >
          <Typography
            variant={isMobile ? "body1" : "h6"}
            fontSize={isMobile ? 14 : 24}
            fontFamily="Somatic"
          >
            {index + 1}
          </Typography>
        </Avatar>
      </Box>
      <Typography
        sx={transparentStyle}
        mt={3}
        fontSize={isMobile ? 14 : 24}
        fontFamily="Somatic"
      >
        {champ.firstname || "Anonymous"}
      </Typography>
      <Typography
        sx={{ color: "#1F1D3A" }}
        fontSize={isMobile ? 10 : 20}
        fontFamily="Somatic"
      >
        {champ.coins} Points
      </Typography>
    </Grid>
  );
};

const TopChamps: React.FC<TopChampsProps> = ({ topChamps, isMobile }) => (
  <Grid
    container
    justifyContent="center"
    spacing={isMobile ? 1 : 2}
    sx={{ mb: isMobile ? 1 : 4, mt: isMobile ? 1 : 5 }}
  >
    {topChamps[2] &&
      renderChampion(
        topChamps[2],
        2,
        isMobile,
        false,
        crown3,
        "rgba(137, 75, 9, 1)",
        "0px 0px 3.62px 0px rgba(137, 75, 9, 1)",
      )}
    {topChamps[0] &&
      renderChampion(
        topChamps[0],
        0,
        isMobile,
        true,
        crown2,
        "rgba(225, 179, 82, 1)",
        "0px 0px 3.45px 0px rgba(225, 179, 82, 1)",
      )}
    {topChamps[1] &&
      renderChampion(
        topChamps[1],
        1,
        isMobile,
        false,
        crown1,
        "rgba(115, 115, 115, 1)",
        "0px 0px 3.45px 0px rgba(115, 115, 115, 1)",
      )}
  </Grid>
);

export default TopChamps;
