import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import StartChamps from "./star-champs";
import TopPortraits from "./top-portraits";
import TopLandscapes from "./top-landscapes";
import Popular from "./popular";
import TopBooks from "./top-books";
import TrendingHot from "./trending-hot";
import Learning from "./learning";
import Safety from "./safety";
import Creativity from "./creativity";
import Achievement from "./achievement";
import Development from "./development";
import Advantages from "./advantages";
import TopClans from "./top-clans";
import Latest from "../../components/latest";
import TalkToUs from "./talk-to-us";

const Content = (props: { isMobile: any }) => {
  const isMobile = props.isMobile;
  return (
    <Box
      sx={{
        backgroundSize: "repeat",
        backgroundPosition: "center center",
        zIndex: 0,
      }}
    >
      <TrendingHot />
      <Learning isMobile={isMobile} />
      <StartChamps />
      <Safety isMobile={isMobile} />
      <TopPortraits />
      <Creativity isMobile={isMobile} />
      <TopLandscapes />
      <Achievement isMobile={isMobile} />
      <Popular />
      <Development isMobile={isMobile} />
      <TopBooks />
      <Advantages isMobile={isMobile} />
      <TopClans isMobile={isMobile} />
      <TalkToUs isMobile={isMobile} />
      <Latest />
    </Box>
  );
};

const Home = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return isMobile ? (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "38px 38px 0px 0px",
        boxShadow:
          "0px -4px 8px 0px #0000000D, 0px 4px 7.8px 0px #00000040 inset",
      }}
    >
      <Content isMobile={isMobile} />
    </Box>
  ) : (
    <Box sx={{ margin: 0, padding: 0, marginTop: "-13%" }}>
      <Content isMobile={isMobile} />
    </Box>
  );
};

export default Home;
