import React, { useState } from "react";
import { Box, Grid2 as Grid, Typography, Button } from "@mui/material";
import home_development from "../../assets/home_development.webp";
import SectionBackground from "../../components/section-background";

interface DevelopmentProps {
  isMobile?: Boolean | null;
}

const transparentStyle = {
  background:
    "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  color: "transparent",
  fontWeight: 700,
  letterSpacing: 1,
};

const Development: React.FC<DevelopmentProps> = ({ isMobile }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const renderImage = () => (
    <Grid size={{ xs: 12, md: 4 }}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img src={home_development} alt="learning" style={{ width: "100%" }} />
      </Box>
    </Grid>
  );

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <SectionBackground />
      {/* Content */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          pt: { xs: 5, md: 25 },
          pr: { xs: 5, md: 5 },
          pb: { xs: 5, md: 15 },
          pl: { xs: 5, md: 5 },
        }}
      >
        {!isMobile && renderImage()}
        <Grid
          size={{ xs: 12, md: 8 }}
          sx={{
            fontFamily: "Righteous",
            display: { xs: "none", md: "block" },
          }}
        >
          <Box p={4}>
            <Typography color="#FFE834" variant="h3" fontSize={40}>
              At Champsverse, we offer tools and content for children’s holistic
              development
            </Typography>
            <Typography
              component="div"
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={{ xs: 14, md: 19 }}
              fontFamily="Somatic"
              textAlign="justify"
              pt={5}
            >
              <span style={{ color: "#FFE834", fontFamily: "Righteous" }}>
                Metaverse Adventures:{" "}
              </span>
              A safe, imaginative digital world where kids learn, explore, and
              unlock rewards.
            </Typography>
            <Typography
              component="div"
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={{ xs: 14, md: 19 }}
              fontFamily="Somatic"
            >
              <span style={{ color: "#FFE834", fontFamily: "Righteous" }}>
                Curated Content:{" "}
              </span>
              Engaging, age-appropriate resources supporting cognitive,
              emotional, and social development at home or in school.
            </Typography>
            <Typography
              component="div"
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={{ xs: 14, md: 19 }}
              fontFamily="Somatic"
            >
              <span style={{ color: "#FFE834", fontFamily: "Righteous" }}>
                Interactive Activities:{" "}
              </span>
              Fun, educational challenges for independent or peer learning in
              digital spaces.
            </Typography>
            <Typography
              component="div"
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={{ xs: 14, md: 19 }}
              fontFamily="Somatic"
            >
              <span style={{ color: "#FFE834", fontFamily: "Righteous" }}>
                Parental Tools:{" "}
              </span>
              Easy-to-use features to help parents monitor and guide their
              child’s online experience.
            </Typography>
            <Typography
              component="div"
              color={isMobile ? "#1F1D3AB2" : "#FFFFFF"}
              variant="body1"
              fontSize={{ xs: 14, md: 19 }}
              fontFamily="Somatic"
            >
              <span style={{ color: "#FFE834", fontFamily: "Righteous" }}>
                Well-being Programs:{" "}
              </span>
              Activities focused on physical activity, mindfulness, and
              emotional resilience for balanced growth
            </Typography>
          </Box>
        </Grid>
        <Grid
          size={{ xs: 12, md: 6 }}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <Typography
            variant={"h6"}
            sx={{
              background:
                "linear-gradient(89.82deg, #E02780 0.16%, #FA4E76 0.17%, #A784EA 38.04%, #ECA75C 62.96%, #F78D6D 83.4%, #CF66C6 99.85%)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
              fontWeight: 700,
              letterSpacing: 1,
              mb: 2,
            }}
            fontSize={20}
            align="center"
          >
            At Champsverse, we offer tools and content for children’s holistic
            development
          </Typography>
        </Grid>
        <Grid
          size={{ xs: 12, md: 6 }}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          <Typography
            component="div"
            align="center"
            fontWeight="normal"
            variant="inherit"
            fontFamily="Somatic"
            fontSize={14}
            color="#1F1D3AB2"
            textAlign="justify"
          >
            <span style={transparentStyle}>Metaverse Adventures:&#160;</span>A
            safe, imaginative digital world where kids learn, explore, and
            unlock rewards.{" "}
            {showMore ? (
              <>
                <Typography
                  component="div"
                  color="#1F1D3AB2"
                  fontSize={{ xs: 14, md: 19 }}
                >
                  <span style={transparentStyle}>Curated Content: </span>
                  Engaging, age-appropriate resources supporting cognitive,
                  emotional, and social development at home or in school.
                </Typography>
                <Typography
                  component="div"
                  color="#1F1D3AB2"
                  fontSize={{ xs: 14, md: 19 }}
                >
                  <span style={transparentStyle}>Interactive Activities: </span>
                  Fun, educational challenges for independent or peer learning
                  in digital spaces.
                </Typography>
                <Typography
                  component="div"
                  color="#1F1D3AB2"
                  fontSize={{ xs: 14, md: 19 }}
                >
                  <span style={transparentStyle}>Parental Tools: </span>
                  Easy-to-use features to help parents monitor and guide their
                  child’s online experience.
                </Typography>
                <Typography
                  component="div"
                  color="#1F1D3AB2"
                  fontSize={{ xs: 14, md: 19 }}
                >
                  <span style={transparentStyle}>Well-being Programs: </span>
                  Activities focused on physical activity, mindfulness, and
                  emotional resilience for balanced growth
                </Typography>
              </>
            ) : (
              "..."
            )}
            <Button
              onClick={toggleShowMore}
              sx={{
                padding: 0,
                minWidth: "unset",
                textTransform: "none",
                fontSize: "inherit",
                fontWeight: "bold",
                color: "#ECA75C",
              }}
            >
              {showMore ? "Read Less" : "Read More"}
            </Button>
          </Typography>
        </Grid>
        {isMobile && renderImage()}
      </Grid>
    </Box>
  );
};

export default Development;
