import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import reacted_omg from "../assets/reacted_omg.json";
import omg from "../assets/omg.json"

interface OmgProps {
  width?: string | number;
  height?: string | number;
}

const Omg: React.FC<OmgProps> = ({ width = "150px", height = "150px" }) => {
  return <Player autoplay loop src={omg} style={{ width, height }} />;
};

export default Omg;
