import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { CoinsCounterProps, UserCounters } from "../types";
import gold_coin from "../assets/gold_coin.json"
import { Player } from "@lottiefiles/react-lottie-player";
interface UserBannerScoresProps {
  userCounters: UserCounters | null;
  coinsData: CoinsCounterProps | null;
  isMobile: Boolean | null;
}

const UserBannerScores: React.FC<UserBannerScoresProps> = ({
  userCounters,
  coinsData,
  isMobile,
}) => {
  const sideScores = (
    <Grid item xs={4} md={4} sx={{ mt: { xs: 1, md: 2 } }}>
      <Grid container spacing={1} sx={{ mt: isMobile ? 0 : "-30px" }}>
        <Grid item xs={6}>
          
          <Player autoplay loop src={gold_coin}  style={
              isMobile ? { width: "80%", height: "80%" } : { width: 80, height: 80 }
            }/>
        </Grid>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        spacing={3}
      >
        <Typography
          fontSize={{ xs: "16.43px", md: "31px" }}
          sx={{ padding: "5px 0 0 0" }}
        >
          {coinsData?.coins || 0}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
        border: "1px solid",
        borderImageSource:
          "linear-gradient(180deg, rgba(252, 252, 252, 0.2) 0%, rgba(150, 150, 150, 0.2) 47%, rgba(133, 129, 129, 0.2) 97%)",
        padding: 2,
        borderRadius: "24px",
        backdropFilter: "blur(12px)",
      }}
    >
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        {isMobile && sideScores}

        <Grid item xs={8} md={8}>
          <Grid container spacing={1} direction="column">
            {[
              {
                name: "Activity",
                score: userCounters?.cws,
                gradient:
                  "linear-gradient(90deg, #081058 -151.39%, #7E31E1 -18.16%, #9421CD 100%)",
                shadow: "rgba(14, 0, 60, 0.5)",
              },
              {
                name: "Popularity",
                score: userCounters?.cps,
                gradient:
                  "linear-gradient(233.66deg, #FFAE01 19.66%, #FF8F01 77.25%)",
                shadow: "rgba(255, 175, 1, 0.5)",
              },
              {
                name: "Goodness",
                score: userCounters?.cgs,
                gradient:
                  "linear-gradient(181.5deg, #EE4343 -2.29%, #FF0000 99.25%)",
                shadow: "rgba(238, 67, 67, 0.5)",
              },
              {
                name: "Resilience",
                score: userCounters?.cas,
                gradient: "linear-gradient(90deg, #14BA56 0%, #FFC700 100%)",
                shadow: "rgba(20, 186, 86, 0.5)",
              },
            ].map(({ name, score, gradient, shadow }) => (
              <Grid
                item
                key={name}
                container
                alignItems="center"
                // justifyContent="space-between"
              >
                <Grid
                  item
                  xs={8}
                  md={8}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "16px",
                      height: "16px",
                      borderRadius: "50%",
                      background: gradient,
                      boxShadow: `0 4px 10px ${shadow}`,
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: "-3px",
                        left: "-3px",
                        right: "-3px",
                        bottom: "-3px",
                        background: gradient,
                        borderRadius: "50%",
                        opacity: 0.4,
                        zIndex: -1,
                      },
                    }}
                  />
                  <Typography
                    fontSize={{ xs: "9.43px", md: "16.79px" }}
                    sx={{ ml: 2 }}
                  >
                    {name} Score
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={4}
                  md={4}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    fontSize={{ xs: "9.43px", md: "16.79px" }}
                    sx={{ mx: 2 }}
                  >
                    :
                  </Typography>
                  <Typography fontSize={{ xs: "9.43px", md: "16.79px" }}>
                    {score || 0}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {!isMobile && sideScores}
      </Grid>
    </Box>
  );
};

export default UserBannerScores;
