import React from "react";

interface IconsContainerProps {
  width?: number;
  height?: number;
  children?: React.ReactNode;
}

const IconsContainer: React.FC<IconsContainerProps> = ({
  width = 879,
  height = 148,
  children,
}) => {
  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 879 148"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_3874_312042)">
          <g filter="url(#filter1_bii_3874_312042)">
            <path
              d="M21.2773 94.575V38.5824C21.2773 29.3637 27.556 21.645 36.7444 20.8976C152.849 11.4539 728.718 10.9811 842.962 21.722C851.933 22.5655 857.918 30.1488 857.918 39.1599V93.9336C857.918 102.882 852.029 110.433 843.125 111.326C728.714 122.8 152.127 122.328 36.5179 112.166C27.4244 111.367 21.2773 103.704 21.2773 94.575Z"
              fill="url(#paint0_linear_3874_312042)"
              fillOpacity="0.2"
            />
            <path
              d="M21.2773 94.575V38.5824C21.2773 29.3637 27.556 21.645 36.7444 20.8976C152.849 11.4539 728.718 10.9811 842.962 21.722C851.933 22.5655 857.918 30.1488 857.918 39.1599V93.9336C857.918 102.882 852.029 110.433 843.125 111.326C728.714 122.8 152.127 122.328 36.5179 112.166C27.4244 111.367 21.2773 103.704 21.2773 94.575Z"
              stroke="url(#paint1_linear_3874_312042)"
              strokeOpacity="0.5"
              strokeWidth="0.936001"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_3874_312042"
            x="0.809326"
            y="0.831977"
            width="877.577"
            height="147.056"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="7.56" />
            <feGaussianBlur stdDeviation="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_3874_312042"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_3874_312042"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_bii_3874_312042"
            x="2.08931"
            y="-5.44804"
            width="875.017"
            height="144.496"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.36001" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_3874_312042"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_3874_312042"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="15.6312" dy="15.6312" />
            <feGaussianBlur stdDeviation="7.81561" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.647059 0 0 0 0 0.647059 0 0 0 0 0.647059 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect2_innerShadow_3874_312042"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="15.6312" dy="15.6312" />
            <feGaussianBlur stdDeviation="7.81561" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="effect2_innerShadow_3874_312042"
              result="effect3_innerShadow_3874_312042"
            />
          </filter>
          <linearGradient
            id="paint0_linear_3874_312042"
            x1="21.2773"
            y1="66.8"
            x2="857.471"
            y2="46.153"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E02780" />
            <stop offset="0.0001" stopColor="#FA4E76" />
            <stop offset="0.38" stopColor="#A784EA" />
            <stop offset="0.63" stopColor="#ECA75C" />
            <stop offset="0.835" stopColor="#F78D6D" />
            <stop offset="1" stopColor="#CF66C6" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3874_312042"
            x1="439.598"
            y1="13.74"
            x2="439.598"
            y2="119.86"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FCFCFC" />
            <stop offset="0.47" stopColor="#969696" />
            <stop offset="0.97" stopColor="#858181" />
          </linearGradient>
        </defs>
      </svg>
      <div
        style={{
          position: "absolute",
          top: "-10px",
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default IconsContainer;
