import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  styled,
  useTheme,
  DialogContent,
  useMediaQuery,
  CircularProgress,
  Typography,
  Box,
  Avatar,
} from "@mui/material";
import axiosInstance from "../../api/axiosConfig";
import { ContestDetail, UserDetail } from "../../types";
import {
  USER_BY_IDS_API,
  CONTEST_ENTRIES_API,
  POST_BY_IDS_API,
  CATEGORIES_FILTER_API,
} from "../../api/endpoint";
import { ConstructionOutlined, Height, Timeline } from "@mui/icons-material";
import style from "../contests/style.module.css";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-container": {
    backdropFilter: "blur(7px)",
  },
  "& .MuiDialog-paper": {
    borderRadius: theme.breakpoints.up("sm") ? "20px" : "18px",
    backdropFilter: "blur(7px)",
    boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
    width: useMediaQuery(theme.breakpoints.down("sm")) ? "85%" : "60%",
    maxWidth: "none",
    height: useMediaQuery(theme.breakpoints.down("sm")) ? "87%" : "80%",
    backgroundColor: "transparent",
  },
  "& .MuiDialogContent-root": {
    padding: 0,
    color: "none",
  },
}));

interface ContestEntry {
  entry_id: string;
  work_id: string;
  user_id: string;
  entry_code: string;
  work_ids: string[];
  contest_id: string;
  score: number;
  position: number;
  title: string;
  type: string;
}

interface ContestDetailDialogProps {
  open: boolean;
  onClose: () => void;
  contestDetail: ContestDetail | null;
  timeline: string;
}

const ContestDetailDialog: React.FC<ContestDetailDialogProps> = ({
  open,
  onClose,
  contestDetail,
  timeline,
}) => {
  const [userData, setUserData] = useState<UserDetail[]>([]);
  const [entries, setEntries] = useState<ContestEntry[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [works, setWorks] = useState<any>([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [pageKey, SetPageKey] = useState<string | null>("");
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [nextPage, setNextPage] = useState(1);
  const navigate = useNavigate();

  const fetchUserDetails = async (entries: ContestEntry[]) => {
    if (contestDetail) {
      try {
        const userIds = entries?.map((item) => item.user_id).join(",");
        const response = await axiosInstance.get(
          `${USER_BY_IDS_API}${userIds}`
        );

        const sortedUserData = entries.map((entry) =>
          response.data.data.find((user: any) => user.uid === entry.user_id)
        );

        if (response?.data.data) {
          setUserData((prevUserData: any) => [
            ...prevUserData,
            ...sortedUserData,
          ]);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    }
  };

  const fetchContestEntries = async () => {
    console.log("fetchentriesss");
    if (contestDetail) {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `${CONTEST_ENTRIES_API}/${contestDetail.contest_id}/entries?count=&page=${pageKey}&title=${title}&type=${type}`
        );

        if (response.data.data) {
          setEntries(response.data.data);
          if (response.data.page) {
            SetPageKey(response.data.page);
          } else {
            SetPageKey(null);
          }
        }
      } catch (error) {
        console.error("Error fetching contest entries:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchPost = async () => {
    if (entries && entries?.length > 0) {
      const postIds = entries
        ?.map((item: any) => (item.work_id ? item.work_id : item.work_ids[0]))
        .join(",");

      const postData = postIds.split(",");

      try {
        const response = await axiosInstance.get(
          `${POST_BY_IDS_API}${postIds}`
        );

        const workData = postData.map((id: string) =>
          response.data.data.find((data: any) => data.post_id === id)
        );

        setWorks((prevWorks: any) => [...prevWorks, ...workData]);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    }
  };
  console.log("works", works);

  const handleCategoryFilter = async (item: any) => {
    setUserData([]);
    setWorks([]);
    SetPageKey("");
    if (title !== item.title) setTitle(item.title);
    if (type !== item.type) setType(item.type);
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        scrollContainerRef.current;
      console.log("looading", loading);
      if (
        scrollTop + clientHeight >= scrollHeight - 10 &&
        !loading &&
        pageKey !== null
      ) {
        console.log("Reached the bottom of the scroll!");
        setNextPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (contestDetail) {
      console.log("ueseffect");
      fetchContestEntries();
    }
  }, [nextPage]);

  useEffect(() => {
    if (entries && entries?.length > 0) {
      fetchPost();
      fetchUserDetails(entries);
    }
  }, [entries]);

  if (!contestDetail) return null;

  const imageUrl = contestDetail.cr_banner
    ? `${process.env.REACT_APP_DEFAULT_AVATAR}/CONTESTS/${contestDetail?.contest_id}/IMAGES/medium/${contestDetail?.ct_banner}`
    : "";

  const handlePostClick = (postId: string) => {
    navigate(`/post/${postId}`);
  };

  const handleProfileClick = (handle:any) => {
    navigate(`/user/${handle}`)
  };

  const CompletedContest: React.FC<{ categories: any[] }> = ({
    categories,
  }) => (
    <Box>
      <Box sx={{ margin: isMobile ? "15px 0 5px 20px" : "32px 0 10px 30px" }}>
        {categories.map((item) => (
          <Box className={style.categories} key={item.id}>
            <Typography
              variant="body1"
              sx={{
                color: "#666",
                padding: isMobile ? "3px" : "7px",
                textAlign: "center",
                fontFamily: "somatic",
                cursor: "pointer",
                fontSize: isMobile ? "10px" : "14.7px",
              }}
              className={style["white-grad"]}
              onClick={() => handleCategoryFilter(item)}
            >
              {`${item.display}${
                item.display === "All"
                  ? ` (${contestDetail.enrolled})`
                  : ` (${item.count})`
              }`}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          padding: isMobile ? "3% 7% 7%" : "3% 4% 4%",
          gridGap: "12px",
          justifyContent: "center",
        }}
      >
        {works?.map((item: any) => {
          const user = userData?.find((user) => user.uid === item.user_id);
          console.log("user",user)
          return (
            <Box
              className={style.detail_cardView}
              sx={{
                height: isMobile ? "145px" : "170px",
                width: isMobile ? "145px" : "170px",
              }}
            >
              <Box onClick={() => handlePostClick(item.post_id)}>
                <img
                  src={`${process.env.REACT_APP_DEFAULT_AVATAR}/${item.user_id}/WORKS/IMAGES/medium/${item.files[0].name}`}
                  alt="Work Image"
                />
              </Box>

              <Box
                className={style.avatarContainer}
                onClick={() => handleProfileClick(user?.handle)}
              >
                <Avatar
                  src={
                    item?.defaultAvatar
                      ? `${process.env.REACT_APP_DEFAULT_AVATAR}/APP/UserAvatars/${user?.avatar}`
                      : `${process.env.REACT_APP_DEFAULT_AVATAR}/${user?.uid}/PROFILE/IMAGES/medium/${user?.avatar}`
                  }
                  style={
                    isMobile
                      ? { width: "23px", height: "23px" }
                      : { width: "35px", height: "35px" }
                  }
                />
                <Typography
                  sx={{
                    color: "white",
                    textAlign: "center",
                    padding: isMobile ? "0 0 0 5px" : "0 0 0 2px",
                    fontSize: isMobile ? "12px" : "14.7px",
                  }}
                >
                  {user?.firstname?.length && user?.firstname?.length > 11
                    ? `${user?.firstname?.substring(0, 11)}...`
                    : user?.firstname}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );

  return (
    <BootstrapDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      scroll="body"
    >
      <DialogContent
        ref={scrollContainerRef}
        onScroll={handleScroll}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          height: "100%",
          scrollbarWidth: "none",
          padding: "16px",
        }}
      >
        <Box className={style.detailPage}>
          <Box sx={{ height: isMobile ? "40%" : "80%" }}>
            <img src={imageUrl} className={style.detailImg}></img>
          </Box>
          <Box className={style.detailName}>
            <Typography
              sx={{
                mt: 2,
                color: contestDetail.theme,
                padding: isMobile ? "0 0 0 15px" : "0 0 0 30px",
                fontSize: isMobile ? "17px" : "30px",
                fontFamily: "somatic",
              }}
            >
              {contestDetail.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mt: 1,
                color: "#666",
                padding: isMobile ? "0 0 0 15px" : "0 14px 0 30px",
                fontFamily: "somatic",
                fontSize: isMobile ? "12px" : "14.7px",
              }}
              className={style.detail_desc}
            >
              {contestDetail.description.join(" ")}
            </Typography>
            {timeline === "completed" && (
              <CompletedContest
                categories={contestDetail.contest_entry_categories}
              />
            )}
            {timeline === "ongoing" && (
              <>
                {" "}
                <Box sx={{ display: "flex", mt: 1 }}>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#666",
                      padding: isMobile ? "0 0 0 15px" : "0 0 0 30px",
                      fontSize: isMobile ? "12px" : "10px",
                      fontFamily: "somatic",
                    }}
                  >{`Strats At:`}</Typography>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#333",
                      fontSize: isMobile ? "12px" : "10px",
                      fontFamily: "somatic",
                      fontWeight: "800",
                      padding: "0 0 0 5px",
                    }}
                  >
                    {contestDetail.from.split("T")[0]}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 1,
                      color: "#666",
                      padding: "0 0 0 30px",
                      fontSize: isMobile ? "12px" : "10px",
                      fontFamily: "somatic",
                    }}
                  >{`Ends At:`}</Typography>

                  <Typography
                    sx={{
                      mt: 1,
                      color: "#333",
                      fontSize: isMobile ? "12px" : "10px",
                      fontFamily: "somatic",
                      fontWeight: "800",
                      padding: "0 0 0 5px",
                    }}
                  >{` ${contestDetail.to.split("T")[0]}`}</Typography>
                </Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: isMobile ? "1rem" : "1.5rem",
                    padding: isMobile ? "12px 0 0 15px" : "32px 0 0 30px",
                  }}
                >{`Enrolled (${contestDetail.enrolled})`}</Typography>
                <Box className={style.detail_userEnroll}>
                  {userData &&
                    userData.map((item: any, index: number) => (
                      <Box key={`${item.uid}-${index}`}>
                        <Avatar
                          src={
                            item?.defaultAvatar
                              ? `${process.env.REACT_APP_DEFAULT_AVATAR}/APP/UserAvatars/Avatar2.png`
                              : `${process.env.REACT_APP_DEFAULT_AVATAR}/${item?.uid}/PROFILE/IMAGES/medium/${item?.avatar}`
                          }
                          sx={{ width: 80, height: 80 }}
                        />
                        <Typography
                          sx={{
                            textAlign: "center",
                            padding: "4px",
                            fontSize: isMobile ? "13px" : "14.7px",
                          }}
                        >
                          {item.firstname.length > 7
                            ? `${item.firstname.substring(0, 7)}...`
                            : item.firstname}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default ContestDetailDialog;
